.loginComp .row {
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.col-sm-9.noSide {
  width: 80%;
  flex: 0 0 100%;
  max-width: 80%;
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 50px;
}

.loginComp .col-sm-6 {
  background: #203552;
  border-radius: 150px 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.col-sm-3.sidebar {
  background: #203552;
  height: 100vh;
  padding: 0;
  width: 20%;
}
h2 span {
  color: #000 !important;
  background: transparent !important;
  font-size: 20px !important;
  text-transform: uppercase !important;
  margin-left: 10px;
}

.col-sm-3.sidebar img {
  width: 100%;
  padding: 30px 20px;
}

.loginComp .col-sm-6 img {
  width: 300px;
  padding: 80px 0 50px;
}

.loginComp form#login {
  width: 80%;
  padding-bottom: 50px;
}

.loginComp label {
  color: #fff !important;
}

.loginComp button {
  border: 1px solid #3D6CAF;
  background: #fff !important;
  color: #294976 !important;
  border-radius: 100px;
  width: 200px;
  height: 50px;
  font-size: 18px;
  font-weight: 600;
  margin-top: 20px;
  box-shadow: unset;
}

.loginComp .ant-input-password,
.inpputt {
  height: 50px;
  border-radius: 12px;
}

.col-sm-3.sidebar ul {
  list-style-type: none;
  padding-left: 0;
}

.container-fluid.servicess .col-sm-12.addnew button.orange{
  background: orange !important;
}
.col-sm-3.sidebar ul a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
  display: block;
  width: 100%;
  font-weight: 600;
  padding: 0 15px;
  padding: 20px 30px;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.col-sm-3.sidebar ul li {
  /* padding: 20px 10px; */
  width: 100%;
  margin-bottom: 10px;
}

.col-sm-3.sidebar ul .active,
.col-sm-3.sidebar ul li:hover {
  background: #fff;
  width: 100%;
  background-clip: padding-box;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  /* xxx */
}
.col-sm-12.barchart .chart-wrapper{
  height: 500px;
}
.col-sm-12.barchart .chart-wrapper canvas{
  min-height: 500px !important;
  width: 100% !important;
}
.col-sm-12.barchart{
  display: flex;
  justify-content: center;
  margin: 50px auto;
}
.row.dashStats{
  margin: 0;
  font-family: Lato;
}
.col-sm-12.paginatitton{
  display: flex;
  justify-content: center;
}
.row.dashStats p{
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 0;
}
.row.dashStats .col-sm-3{
  border: 2px solid #203552;
  padding: 30px 20px;
  width: calc(25% - 30px);
  border-radius: 20px;
  text-align: center;
  margin: 15px;
  /* border-color: linear-gradient(90.95deg, #549AFF 0.81%, #192D49 154.84%); */
}
.col-sm-3.sidebar ul .active a,
.col-sm-3.sidebar ul li:hover a {
  color: #549AFF !important;
  /* background: linear-gradient(90.95deg, #549AFF 0.81%, #192D49 154.84%);
background-clip  : text;
  -webkit-text-fill-color: transparent; */
  background: #fff;
}

.col-sm-3.sidebar ul .active {
  color: #549AFF !important;
  /* background: linear-gradient(90.95deg, #549AFF 0.81%, #192D49 154.84%);
  background-clip: text;
  -webkit-text-fill-color: transparent; */
  background: #fff;

}
.noDataa{
  text-align: center;
  height: 500px;
  font-family: Lato;
  font-size: 20px;
  font-weight: 600;
}
button.spinnnnerr svg {
  color: #fff;
}
.servicess h2 {
  font-size: 36px;
  font-weight: 700;
  background: linear-gradient(90.95deg, #192D49 0.81%, #549AFF 154.84%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #549AFF !important;
}
.modal-body .container-fluid.servicess{
  padding-top: 0;
}
.modal-header h5{
  font-weight: 700;
  background: linear-gradient(90.95deg, #192D49 0.81%, #549AFF 154.84%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #549AFF !important;
  font-size: 24px;
}
.container-fluid.servicess {
  padding-top: 40px;
}

.container-fluid.servicess .col-sm-12.addnew {
  justify-content: space-between;
  display: flex;
}

.container-fluid.servicess .col-sm-12.addnew button,
.row .ant-btn-primary {
  background: linear-gradient(261.42deg, #3967A7 42.79%, #203552 96.73%) !important;
  color: #fff !important;
  border: unset !important;
  border-radius: 100px;
  width: 200px;
  font-family: Lato;
  height: 50px;
  font-size: 16px;
  font-weight: 500;
}

.delBtn {
  background: linear-gradient(261.42deg, #3967A7 42.79%, #203552 96.73%) !important;
  color: #fff !important;
  border: unset !important;
  border-radius: 100px;
  font-size: 16px;
  font-family: Lato;
  min-width: 100px;
  max-width: 130px;
  height: 45px;
}

.container-fluid.servicess .col-sm-12.ttable thead {
  background: linear-gradient(261.42deg, #3967A7 42.79%, #203552 96.73%) !important;
  color: #fff !important;
  border-radius: 100px;
  font-size: 16px;
  font-family: Lato;
  font-weight: 500;
}

.container-fluid.servicess .col-sm-12.ttable thead th,
.container-fluid.servicess .col-sm-12.ttable tbody td {
  padding: 15px 20px;
}

.container-fluid.servicess .col-sm-12.ttable {
  padding-top: 40px;
  height: 80vh;
  overflow-y: scroll ;
}

::-webkit-scrollbar{
  display: none;
}

.container-fluid.servicess .col-sm-12.ttable table {
  width: 100%;
  border-collapse: collapse;
}

.actionsss {
  display: flex;
  gap: 10px;
}
/* td .ant-select-selector{
  border: unset !important;
} */

.container-fluid.servicess .col-sm-12.ttable tbody td.deleete svg {
  font-size: 24px;
}

.container-fluid.servicess .col-sm-12.ttable tbody td {
  cursor: pointer;
}

.container-fluid.servicess .col-sm-12.ttable tbody td.deleete svg:hover {
  transform: scale(1.1);
  color: red;
}
.red div, .green div, .orange div{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}
.red div{
  background-color: red;
}
.green div{
  background-color: green;
}
.orange div{
  background-color: orange;
}
.red, .green, .orange{
  display: flex;
  align-items: center;
}
.col-sm-12.bttnss {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-upload-list-item-container .ant-tooltip{
  display: none !important;
}
.surree p {
  font-family: Lato;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 20px;
}
.ant-upload-wrapper .ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item-error
{
  border-color: #d9d9d9 !important;
}
.modal-header {
  border: unset !important;
}

.cancelll {
  background: #fff !important;
  /* border: unset !important; */
  font-family: Lato;
  font-size: 16px;
  font-weight: 600;
  color: #294976 !important;
  border-radius: 100px;
  box-shadow: none !important;
  margin-right: 30px;
  width: 100px;
  height: 45px;
  border: 1px solid #3D6CAF !important;
}

.container-fluid.servicess.userdetailll .ant-image-mask{
  border-radius: 50%;
}

.ant-image img{
  object-fit: cover;
}

.container-fluid.servicess img{
  height: 300px;
  object-fit: cover;
}

.container-fluid.servicess.userdetailll input{
  height: 50px;
  border-radius: 12px;
  font-weight: 400;
}
.container-fluid.servicess label{
  font-weight: 500;
}

.container-fluid.servicess input, .container-fluid.servicess .ant-input-password{
  height: 50px;
  border-radius: 12px;
}
.container-fluid.servicess .ant-input-password input{
  height: auto !important;
}

.container-fluid.servicess textarea{
  border-radius: 12px;
}

@media screen and (max-width: 1370px) {
  .container-fluid.servicess .col-sm-12.ttable {
    padding-top: 20px;
    /* height: 75vh; */
  }
  .servicess h2 {
    font-size: 30px;
  }
  .col-sm-3.sidebar ul a{
    font-size: 18px;
  }
  .col-sm-9.noSide{
    padding-bottom: 10px;
  }
  .container-fluid.servicess .col-sm-12.ttable tbody td {
    padding: 12px 15px;
    font-size: 14px;
  }
  .container-fluid.servicess .col-sm-12.paginatitton{
    padding-top: 20px;
  }   
  .col-sm-12.ffoortwenty{
    height: 20vh;
  }
  .col-sm-12.ffoorEightty{
    height: 80vh;
    overflow: scroll;
    padding: 30px 12px;
  }
  .col-sm-12.barchart .chart-wrapper canvas {
    /* min-height: 385px !important;
    max-height: 400px !important; */
    min-width: 100% !important;
    width: 100% !important;
  }
  .col-sm-12.barchart .chart-wrapper {
    height: auto !important;
  }
  .col-sm-12.barchart{
      margin-bottom: 0;
  }
  .row.dashStats h3{
    font-size: 22px !important;
  }
  .row.dashStats .col-sm-3 {
    padding: 20px 10px;
    width: calc(25% - 20px);
    margin: 10px; 
  }
}